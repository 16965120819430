import styled from "styled-components";

const OlList = styled.ol`
  list-style-type: decimal;
  li {
    &:before {
        content: "";
        min-width: 10px;
        max-width: 10px;
        min-height: 10px;
        max-height: 10px;
        display: block;
        border-radius: 500px;
        margin-right: 15px;
        margin-top: 10px;
      }
  } 
`;

export default OlList;
