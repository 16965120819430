import React from "react";
import { Link } from "gatsby";

// import imgL1Logo from "../../assets/image/png/l1-logo.png";
// import imgL1LogoWhite from "../../assets/image/png/logo-white.png";
import kestoLogo from "../../assets/image/png/kesto-logo.png";
import smallKestoLogo from "../../assets/image/png/small-kesto-logo.png";

const Logo = ({ white, small, height, className = "", ...rest }) => {
  const logoHeight = small ? 60 : 80;
  return (
    <Link to="/" className={`${className}`} {...rest}>
      {white ? (
        <img src={kestoLogo} height={logoHeight} alt="" />
      ) : (
        <img src={kestoLogo} height={logoHeight} alt="" />
      )}
    </Link>
  );
};

export default Logo;
